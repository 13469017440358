import { useState, useCallback, useRef, useMemo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useNodeApi } from "../../hooks/useNodeApi";
import { Citation, CitationWithState, ExtractLogResponse, UserDocumentDetails } from "../../services/api";
import { Button } from "../ui/button";
import { HomeSidebarV1 } from "../HomeSidebarV1";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";
import { ExternalLink, Search, ChevronRight, FileText, ChevronDown } from "lucide-react";
import toast from 'react-hot-toast';
import { Input } from "../ui/input";
import { PDFPreviewSlider } from "../compare/PDFPreviewSlider";
import { produce } from "immer";
import { withRequiredAuthInfo } from "@propelauth/react";
import axios from 'axios';
import { usePolling } from "../../lib/usePolling";
import { POLLING_CONFIG } from "../../config/pollingConfig";
import DocumentPreviewDialog from '../DocumentPreviewDialog';
import { cn } from "../../lib/utils";
import React from "react";

type SimpleValue = string | number | boolean | null;
type NestedValue = string | number | boolean | null | Record<string, unknown> | Array<unknown>;
type ComplexDataType =
  | Array<Record<string, NestedValue>>
  | Record<string, NestedValue>
  | Array<SimpleValue>;

interface ExtractedRow {
  title: string;
  value: SimpleValue | ComplexDataType;
  citations: Citation[];
}

const isComplexData = (value: unknown): value is ComplexDataType => {
  if (!value) return false;

  if (Array.isArray(value)) {
    return value.length > 0;
  }

  if (typeof value === 'object' && value !== null) {
    return Object.keys(value).length > 0;
  }

  return false;
};

function TableRowWithExpansion({ row, rowIndex, handleCellClick }: {
  row: ExtractedRow;
  rowIndex: number;
  handleCellClick: (rowIndex: number, citations: Citation[]) => void;
}) {
  const [isOpen, setIsOpen] = useState(true);
  const hasComplexData = row.value !== null && isComplexData(row.value);

  const renderParentRow = (showChevron = true) => (
    <TableRow>
      <TableCell className="border-y border-gray-200">
        <div
          className={cn(
            "flex items-center justify-between w-full",
            hasComplexData && "cursor-pointer hover:bg-gray-50"
          )}
          onClick={() => hasComplexData && setIsOpen(!isOpen)}
        >
          <span className="font-medium">
            {row.title.split('_').map(word =>
              word.charAt(0).toUpperCase() + word.slice(1)
            ).join(' ')}
          </span>
          {hasComplexData && showChevron && (
            <ChevronDown
              className={cn(
                "h-4 w-4 text-gray-500 transition-transform duration-200",
                isOpen ? "transform rotate-180" : ""
              )}
            />
          )}
        </div>
      </TableCell>
      <TableCell className="border border-gray-200" />
    </TableRow>
  );

  interface NestedCitation {
    [key: string]: Citation[];
  }

  const renderNestedContent = () => {
    if (!hasComplexData || !row.value) return null;
    const rows: JSX.Element[] = [];

    if (Array.isArray(row.value)) {
      if (row.value.length === 0 || typeof row.value[0] !== 'object' || row.value[0] === null) {
        return (
          <TableRow>
            <TableCell className="border-y border-gray-200">
              <span className="font-medium">
                {row.title.split('_').map(word =>
                  word.charAt(0).toUpperCase() + word.slice(1)
                ).join(' ')}
              </span>
            </TableCell>
            <TableCell
              className={cn(
                "border border-gray-200",
                row.citations?.length && "cursor-pointer hover:bg-gray-50"
              )}
              onClick={() => {
                if (row.citations?.length) {
                  handleCellClick(rowIndex, row.citations);
                }
              }}
            >
              {row.value.join(', ')}
            </TableCell>
          </TableRow>
        );
      }

      row.value.forEach((item, index) => {
        if (typeof item === 'object' && item !== null) {
          const itemCitations = (row.citations?.[index] as unknown) as NestedCitation;

          rows.push(
            <React.Fragment key={index}>
              {index > 0 && renderParentRow(false)}
              {Object.entries(item).map(([key, value]) => {
                const propertyCitations = itemCitations?.[key] || [];

                return (
                  <TableRow key={`${index}-${key}`}>
                    <TableCell className="border-y border-gray-200 pl-8">
                      <span className="font-medium">
                        {key.split('_').map(word =>
                          word.charAt(0).toUpperCase() + word.slice(1)
                        ).join(' ')}
                      </span>
                    </TableCell>
                    <TableCell
                      className={cn(
                        "border border-gray-200",
                        propertyCitations && "cursor-pointer hover:bg-gray-50"
                      )}
                      onClick={() => {
                        if (propertyCitations.length) {
                          handleCellClick(rowIndex, propertyCitations);
                        }
                      }}
                    >
                      {String(value ?? '')}
                    </TableCell>
                  </TableRow>
                );
              })}
            </React.Fragment>
          );
        }
      });
      return [renderParentRow(true), ...rows];
    } else {
      Object.entries(row.value).forEach(([key, value]) => {
        rows.push(
          <TableRow key={key} className="bg-gray-50">
            <TableCell className="border-y border-gray-200 pl-8">
              <span className="font-medium">
                {key.split('_').map(word =>
                  word.charAt(0).toUpperCase() + word.slice(1)
                ).join(' ')}
              </span>
            </TableCell>
            <TableCell
              className={cn(
                "border border-gray-200",
                row.citations?.length && "cursor-pointer hover:bg-gray-50"
              )}
              onClick={() => {
                if (row.citations?.length) {
                  handleCellClick(rowIndex, row.citations);
                }
              }}
            >
              {String(value ?? '')}
            </TableCell>
          </TableRow>
        );
      });
      return [renderParentRow(true), ...rows];
    }
  };

  return (
    <>
      {!hasComplexData ? (
        <TableRow>
          <TableCell className="border-y border-gray-200">
            <span className="font-medium">
              {row.title.split('_').map(word =>
                word.charAt(0).toUpperCase() + word.slice(1)
              ).join(' ')}
            </span>
          </TableCell>
          <TableCell
            className={cn(
              "border border-gray-200",
              row.citations?.length && "cursor-pointer hover:bg-gray-50"
            )}
            onClick={() => {
              if (row.citations?.length) {
                handleCellClick(rowIndex, row.citations);
              }
            }}
          >
            {String(row.value ?? '')}
          </TableCell>
        </TableRow>
      ) : (
        isOpen ? renderNestedContent() : renderParentRow(true)
      )}
    </>
  );
}

export const ExtractDetail = withRequiredAuthInfo(({ accessToken }: { accessToken: string | null }) => {
  const navigate = useNavigate();
  const { id: extractLogId } = useParams<{ id: string }>();
  const { getExtractionResults, getPdfUrl, exportExtractLog, fetchUserDocument } = useNodeApi(accessToken);
  const [extractLog, setExtractLog] = useState<ExtractLogResponse | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const lastPollTimeRef = useRef(0);
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [pdfUrls, setPdfUrls] = useState<Map<number, string>>(new Map());
  const [currentPdfUrl, setCurrentPdfUrl] = useState<string | null>(null);
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [selectedCitations, setSelectedCitations] = useState<CitationWithState[]>([]);
  const [filterQuery, setFilterQuery] = useState("");
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [previewFileType, setPreviewFileType] = useState<'pdf' | 'image'>('pdf');
  const [documentDetails, setDocumentDetails] = useState<UserDocumentDetails | null>(null);
  const [isLoadingDocument, setIsLoadingDocument] = useState(false);
  const [documentError, setDocumentError] = useState<string | null>(null);

  const extractedData = useMemo((): ExtractedRow[] => {
    if (!extractLog?.result?.data) return [];

    return Object.entries(extractLog.result.data).map(([key, value]) => ({
      title: key,
      value: value ?? null,
      citations: extractLog.result.citations[key] || []
    }));
  }, [extractLog]);

  const filteredRows = useMemo(() => {
    if (!extractedData) return [];

    const query = filterQuery.toLowerCase().trim();
    if (!query) return extractedData;

    return extractedData.filter(row => {
      const stringValue = String(row.value ?? '');
      return row.title.toLowerCase().includes(query) ||
             stringValue.toLowerCase().includes(query);
    });
  }, [extractedData, filterQuery]);

  usePolling(
    () => {
      if (!extractLogId) {
        throw new Error('No extraction ID provided.');
      }
      return getExtractionResults(extractLogId);
    },
    {
      interval: POLLING_CONFIG.INITIAL_INTERVAL,
      maxInterval: POLLING_CONFIG.MAX_INTERVAL,
      backoffMultiplier: POLLING_CONFIG.BACKOFF_MULTIPLIER,
      enabled: true,
      lastPollTimeRef,
      continuePollingOnSuccess: true,
      isComplete: (result) => {
        return result.status === 'completed' || result.status === 'failed';
      },
      onSuccess: (result) => {
        setExtractLog(result);
        setError(null);
        setIsLoading(false);

        if (result.status === 'failed') {
          toast.error('Extraction failed');
          return false;
        }
      },
      onError: (error) => {
        setIsLoading(false);
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          navigate('/404');
          return false;
        }
        setError(error instanceof Error ? error.message : 'Unknown error');
        return true;
      },
      onOfflineChange: (isOffline) => {
        if (isOffline) {
          toast.error("Failed to fetch extraction results. Please try again.");
        } else {
          toast.success("Connection restored. Extract log updated.");
        }
      }
    }
  );

  useEffect(() => {
    const fetchDocumentDetails = async () => {
      if (!extractLog?.request?.document) return;

      setIsLoadingDocument(true);
      try {
        const details = await fetchUserDocument(extractLog.request.document);
        setDocumentDetails(details);
        setDocumentError(null);
      } catch (error) {
        console.error("Failed to fetch document details:", error);
        setDocumentError("Failed to load document details");
      } finally {
        setIsLoadingDocument(false);
      }
    };

    fetchDocumentDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extractLog?.request?.document]);

  const handleExport = async () => {
    if (!extractLog) return;

    setIsExporting(true);
    try {
      const downloadUrl = await exportExtractLog(extractLog._id);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.target = '_blank';
      link.download = `extraction_${extractLog._id}.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success('Export successful. Your download should begin shortly.');
    } catch (error) {
      console.error('Export failed:', error);
      toast.error('Failed to export extraction. Please try again.');
    } finally {
      setIsExporting(false);
    }
  };

  const handleCellClick = useCallback(async (rowIndex: number, citations: Citation[]) => {
    setIsSliderOpen(true);
    setIsLoadingPdf(true);
    setCurrentPdfUrl(null);

    const normalizedCitations = Array.isArray(citations)
      ? citations.flat().filter(Boolean)
      : [];

    setSelectedCitations(normalizedCitations.map(citation => ({ citation, state: 'match' })));

    try {
      const documentId = extractLog?.request?.document;
      if (!documentId) {
        throw new Error('Document ID not found');
      }

      if (!pdfUrls.has(0)) {
        const url = await getPdfUrl(documentId);
        setPdfUrls(produce(draft => {
          draft.set(0, url);
        }));
        setCurrentPdfUrl(url);
      } else {
        setCurrentPdfUrl(pdfUrls.get(0) || null);
      }
    } catch (error) {
      console.error("Failed to fetch PDF URL:", error);
      toast.error("Failed to load PDF preview. Please try again.");
    } finally {
      setIsLoadingPdf(false);
    }
  }, [extractLog, getPdfUrl, pdfUrls]);

  const handleDocumentClick = async () => {
    try {
      const documentId = extractLog?.request?.document;
      if (documentId) {
        const url = await getPdfUrl(documentId);
        setPreviewFileType('pdf');
        setPreviewUrl(url);
        setIsPreviewOpen(true);
      }
    } catch (error) {
      console.error("Failed to fetch PDF URL:", error);
      toast.error("Failed to load PDF preview");
    }
  };

  const documentTitle = useMemo(() => {
    if (isLoadingDocument) return "Loading...";
    if (documentError) return "Document";
    if (documentDetails?.filename) return documentDetails.filename;
    return `${extractLog?.request?.document || ""}`;
  }, [documentDetails, isLoadingDocument, documentError, extractLog?.request?.document]);

  if (isLoading) {
    return <ExtractDetailSkeleton />;
  }

  if (error) {
    return <ExtractDetailError error={error} />;
  }

  if (!extractLog || !extractLog.result) {
    return <ExtractDetailSkeleton />;
  }

  return (
    <div className="flex h-screen overflow-hidden">
      <HomeSidebarV1 />
      <div className="flex-1 overflow-auto min-h-screen bg-white py-2 ml-56">
        <div className="mx-auto px-2">
          <div className="bg-white h-full overflow-y-auto">
            <div className="p-6">
              <div>
                <div className="flex items-center justify-between mb-6">
                  <div className="flex items-center text-md text-gray-500">
                    <span className="hover:text-gray-700 cursor-pointer" onClick={() => navigate('/extract')}>Extract AI</span>
                    <ChevronRight className="h-4 w-4 mx-2" />
                    <span className="font-medium text-gray-900">Details</span>
                  </div>
                </div>

                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center space-x-2">
                    <div className="relative">
                      <Input
                        type="text"
                        placeholder="Filter documents..."
                        className="pl-8 pr-4 py-1 w-48 h-9"
                        value={filterQuery}
                        onChange={(e) => setFilterQuery(e.target.value)}
                      />
                      <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                    </div>
                  </div>
                  <Button
                    variant="outline"
                    size="sm"
                    className="text-gray-700 hover:text-gray-900 border border-gray-300 hover:bg-white"
                    onClick={handleExport}
                    disabled={isExporting}
                  >
                    <ExternalLink className="h-4 w-4 mr-2" />
                    {isExporting ? 'Exporting...' : 'Export'}
                  </Button>
                </div>
              </div>

              <div className="mb-6">
                <h3 className="text-lg font-semibold mb-4">Uploaded document</h3>
                <div className="grid grid-cols-1 gap-4">
                  {extractLog?.request?.document && (
                    <div
                      onClick={handleDocumentClick}
                      className="flex items-center p-4 rounded-lg border border-gray-200 bg-white hover:bg-gray-50 cursor-pointer"
                    >
                      <div className="w-8 h-8 rounded-full bg-purple-100 flex items-center justify-center mr-3">
                        <FileText className="w-4 h-4 text-purple-600" />
                      </div>
                      <div className="flex flex-col">
                        <span className="text-sm font-medium text-gray-900">
                          {documentTitle}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <h3 className="text-lg font-semibold mb-4">Extracted Data</h3>
              <Table className="border-collapse border border-gray-200">
                <TableHeader>
                  <TableRow>
                    <TableHead className="border-y border-gray-200">Field</TableHead>
                    <TableHead className="border border-gray-200">Value</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {filteredRows.map((row, rowIndex) => (
                    <TableRowWithExpansion
                      key={rowIndex}
                      row={row}
                      rowIndex={rowIndex}
                      handleCellClick={handleCellClick}
                    />
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </div>
      <PDFPreviewSlider
        isOpen={isSliderOpen}
        onClose={() => {
          setIsSliderOpen(false);
          setCurrentPdfUrl(null);
          setSelectedCitations([]);
        }}
        pdfUrl={currentPdfUrl}
        citations={selectedCitations}
        isLoading={isLoadingPdf}
      />
      <DocumentPreviewDialog
        isOpen={isPreviewOpen}
        onClose={() => {
          setPreviewUrl("");
          setIsPreviewOpen(false);
        }}
        previewUrl={previewUrl}
        fileType={previewFileType}
      />
    </div>
  );
})

function ExtractDetailSkeleton() {
  const navigate = useNavigate();
  return (
    <div className="flex h-screen overflow-hidden">
      <HomeSidebarV1 />
      <div className="flex-1 overflow-auto min-h-screen bg-white py-2 ml-56">
        <div className="mx-auto px-2">
          <div className="bg-white h-full overflow-y-auto">
            <div className="p-6">
              <div className="flex items-center justify-between mb-6">
                <div className="flex items-center text-md text-gray-500">
                  <span className="hover:text-gray-700 cursor-pointer" onClick={() => navigate('/extract')}>Extract AI</span>
                  <ChevronRight className="h-4 w-4 mx-2" />
                  <span className="font-medium text-gray-900">Details</span>
                </div>
              </div>

              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center space-x-2">
                  <div className="relative">
                    <Input
                      type="text"
                      placeholder="Filter fields..."
                      className="pl-8 pr-4 py-1 w-48 h-9"
                      disabled
                    />
                    <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                  </div>
                </div>
                <Button
                  variant="outline"
                  size="sm"
                  className="text-gray-700 hover:text-gray-900 border border-gray-300 hover:bg-white"
                  disabled
                >
                  <ExternalLink className="h-4 w-4 mr-2" />
                  Export
                </Button>
              </div>

              <div className="mb-6">
                <h3 className="text-lg font-semibold mb-4">Uploaded document</h3>
                <div className="grid grid-cols-1 gap-4">
                  <div className="flex items-center p-4 rounded-lg border border-gray-200 bg-white">
                    <div className="w-8 h-8 rounded-full bg-gray-100 animate-pulse flex items-center justify-center mr-3">
                      <FileText className="w-4 h-4 text-gray-400" />
                    </div>
                    <div className="h-4 bg-gray-200 rounded animate-pulse w-48" />
                  </div>
                </div>
              </div>

              <h3 className="text-lg font-semibold mb-4">Extracted Data</h3>
              <div className="mb-6">
                <Table className="border-collapse border border-gray-200">
                  <TableHeader>
                    <TableRow>
                      <TableHead className="border-y border-gray-200">Field</TableHead>
                      <TableHead className="border border-gray-200">Value</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {[...Array(8)].map((_, index) => (
                      <TableRow key={index}>
                        <TableCell className="border-y border-gray-200">
                          <div className="h-4 bg-gray-200 rounded animate-pulse w-32" />
                        </TableCell>
                        <TableCell className="border border-gray-200">
                          <div className="h-4 bg-gray-200 rounded animate-pulse" style={{ width: `${Math.random() * 30 + 50}%` }} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ExtractDetailError({ error }: { error: string }) {
  return (
    <div className="flex">
      <HomeSidebarV1 />
      <div className="flex-1 min-h-screen bg-white py-2 ml-56">
        <div className="mx-auto px-2">
          <div className="bg-white h-full overflow-y-auto">
            <div className="p-6">
              <h1 className="text-xl font-semibold mb-6">Error</h1>
              <p className="text-red-500 mb-4">{error}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
