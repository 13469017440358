import { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useNodeApi } from "../../hooks/useNodeApi";
import { SubmissionEmailData } from "../../services/api";
import { HomeSidebarV1 } from "../HomeSidebarV1";
import { ChevronRight, FileText } from "lucide-react";
import toast from 'react-hot-toast';
import { Card, CardContent } from "../ui/card";
import { withRequiredAuthInfo } from "@propelauth/react";
import axios from 'axios';
import { SubmissionAIChat } from './SubmissionAIChat'
import { ScrollArea } from "../ui/scroll-area";

const POLL_INTERVAL = 5000;
const POLL_TIMEOUT = 300000;
export const SubmissionDetail = withRequiredAuthInfo(({ accessToken }: { accessToken: string | null }) => {
    const navigate = useNavigate();
    const { id: submissionId } = useParams<{ id: string }>();
    const { fetchSubmissionEmailById } = useNodeApi(accessToken);
    const [submissionEmail, setSubmissionEmail] = useState<SubmissionEmailData | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [isOffline, setIsOffline] = useState(false);
    const lastPollTimeRef = useRef(0);

    const loadCompareLog = useCallback(async (bypassTimeCheck = false) => {
        if (!submissionId) {
            setError('No comparison ID provided.');
            setIsLoading(false);
            return;
        }

        const now = Date.now();
        if (!bypassTimeCheck && now - lastPollTimeRef.current < POLL_INTERVAL) {
            return;
        }

        let retries = 0;
        const maxRetries = Math.ceil(POLL_TIMEOUT / POLL_INTERVAL);

        const attemptFetch = async (): Promise<void> => {
            try {
                const result = await fetchSubmissionEmailById(submissionId);
                if (result) {
                    setSubmissionEmail(result);
                    setIsLoading(false);
                } else if (retries < maxRetries) {
                    retries++;
                    setTimeout(() => attemptFetch(), POLL_INTERVAL);
                } else {
                    setError('Connection to server lost. Please check back later.');
                    setIsLoading(false);
                }
                lastPollTimeRef.current = now;
                if (isOffline) {
                    setIsOffline(false);
                    toast.success("Connection restored. Compare log updated.");
                }
            } catch (error) {
                console.error("Failed to fetch submission emails:", error);
                if (retries > 0 && axios.isAxiosError(error) && error.status === 404) {
                    navigate('/404');
                    return;
                }
                if (retries < maxRetries) {
                    retries++;
                    setTimeout(() => attemptFetch(), POLL_INTERVAL);
                } else {
                    if (!isOffline) {
                        setIsOffline(true);
                        toast.error("Failed to fetch submission emails. Please try again.");
                    }
                    setError('Failed to fetch submission emails. Please try again.');
                    setIsLoading(false);
                }
            }
        };

        attemptFetch();
    }, [submissionId, fetchSubmissionEmailById, isOffline, navigate]);

    const loadCompareLogRef = useRef(loadCompareLog);

    useEffect(() => {
        loadCompareLogRef.current = loadCompareLog;
    }, [loadCompareLog]);

    useEffect(() => {
        loadCompareLogRef.current(true);
    }, []);

    return (
        <div className="flex h-screen overflow-hidden">
            <HomeSidebarV1 />
            <div className="flex-1 flex flex-col overflow-hidden ml-56">
                <div className="bg-gray-50 py-8 px-8">
                    <div className="max-w-7xl mx-auto flex items-center">
                        <div className="flex items-center text-md text-gray-500">
                            <span
                                className="hover:text-gray-700 cursor-pointer"
                                onClick={() => navigate('/submissions')}
                            >
                                Submissions
                            </span>
                            <ChevronRight className="h-4 w-4 mx-2" />
                            <span className="font-medium text-gray-900">Email Conversation</span>
                        </div>
                    </div>
                </div>

                <div className="flex-1 flex overflow-hidden bg-gray-50 px-6">
                    <div className="flex gap-4 w-full max-w-7xl mx-auto">
                        <div className="flex-1">
                            {isLoading ? (
                                <SubmissionDetailSkeleton />
                            ) : error ? (
                                <SubmissionDetailError error={error} />
                            ) : submissionEmail ? (
                                <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden">
                                    <div className="p-4">
                                        <h2 className="text-lg font-semibold mb-2">
                                            {submissionEmail.title || "Untitled Email"}
                                        </h2>
                                        <div className="h-px bg-gray-200 mb-4 my-4" />
                                        <ScrollArea
                                            className="h-[calc(100vh-200px)]"
                                            type="auto"
                                        >
                                            <div className="space-y-6">
                                                <Card className="shadow-none border-none">
                                                    <CardContent className="p-4">
                                                        <div className="space-y-2">
                                                            <div className="flex items-center">
                                                                <span className="text-sm font-medium text-blue-600">
                                                                    Further AI
                                                                </span>
                                                                <span className="text-xs text-gray-500 ml-2">
                                                                    automated@furtherai.com
                                                                </span>
                                                            </div>
                                                            <div className="text-sm text-gray-700">
                                                                <div className="space-y-4">
                                                                    <p>Hey {submissionEmail?.body_without_previous_email_html?.match(/Hey ([^,]+)/)?.[1] || "there"},</p>
                                                                    <div>
                                                                        <p className="font-medium mb-2">Risk Summary</p>
                                                                        <ul className="list-disc pl-4 space-y-1">
                                                                            <li>Effective Date: {submissionEmail?.body_without_previous_email_html?.match(/Effective Date: ([^<]+)/)?.[1] || "Not found"}</li>
                                                                            <li>Quote Need By Date: {submissionEmail?.body_without_previous_email_html?.match(/Quote Need By Date: ([^<]+)/)?.[1] || "Not found"}</li>
                                                                            <li>Controlled Business: {submissionEmail?.body_without_previous_email_html?.match(/Controlled Business: ([^<]+)/)?.[1] || "Not found"}</li>
                                                                            <li>Type of Business: {submissionEmail?.body_without_previous_email_html?.match(/Type of Business: ([^<]+)/)?.[1] || "Not found"}</li>
                                                                            <li>Total TIV: {submissionEmail?.body_without_previous_email_html?.match(/Total TIV: ([^<]+)/)?.[1] || "Not found"}</li>
                                                                            <li>Alert: {submissionEmail?.body_without_previous_email_html?.match(/Alert: ([^<]+)/)?.[1] || "Not found"}</li>
                                                                        </ul>
                                                                    </div>

                                                                    <div>
                                                                        <p className="font-medium mb-2">Location Summary</p>
                                                                        <ul className="list-disc pl-4 space-y-1">
                                                                            <li>Location: {submissionEmail?.body_without_previous_email_html?.match(/Location - ([^<]+)/)?.[1] || "Not found"}</li>
                                                                            <li>Number of Buildings: {submissionEmail?.body_without_previous_email_html?.match(/Number of Buildings: ([^<]+)/)?.[1] || "Not found"}</li>
                                                                            <li>Occupancy Description: {submissionEmail?.body_without_previous_email_html?.match(/Occupancy Description: ([^<]+)/)?.[1] || "Not found"}</li>
                                                                            <li>Year Built: {submissionEmail?.body_without_previous_email_html?.match(/Year Built: ([^<]+)/)?.[1] || "Not found"}</li>
                                                                            <li>Cost Per Square Foot: {submissionEmail?.body_without_previous_email_html?.match(/Cost Per Square Foot: ([^<]+)/)?.[1] || "Not found"}</li>
                                                                            <li>Full Address: {submissionEmail?.body_without_previous_email_html?.match(/Full Address: ([^<]+)/)?.[1] || "Not found"}</li>
                                                                        </ul>
                                                                    </div>

                                                                    <div>
                                                                        <p className="font-medium mb-2">Price Targets</p>
                                                                        <ul className="list-disc pl-4 space-y-1">
                                                                            <li>Target Price: {submissionEmail?.body_without_previous_email_html?.match(/Target Price or Rate: ([^<]+)/)?.[1] || "Not found"}</li>
                                                                            <li>Desired Coverage: {submissionEmail?.body_without_previous_email_html?.match(/Desired Coverage: ([^<]+)/)?.[1] || "Not found"}</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {submissionEmail?.output_email_attachment_blobs &&
                                                             submissionEmail.output_email_attachment_blobs.length > 0 && (
                                                                <div className="flex flex-wrap gap-2 mt-4">
                                                                    {submissionEmail.output_email_attachment_blobs.map((document) => (
                                                                          <a
                                                                          key={document.name}
                                                                          href={document.url}
                                                                          target="_blank"
                                                                          rel="noopener noreferrer"
                                                                          className="inline-flex items-center text-sm text-gray-700 bg-white border border-gray-200 rounded-md px-3 py-1.5 hover:bg-gray-100"
                                                                        >
                                                                            <div className="flex items-center space-x-2">
                                                                                <div className="w-7 h-7 rounded-md bg-purple-50 flex items-center justify-center">
                                                                                    <FileText className="w-3 h-3 text-purple-600" />
                                                                                </div>
                                                                                <span className="text-xs text-gray-900 truncate max-w-[180px]">
                                                                                    {document.name}
                                                                                </span>
                                                                            </div>
                                                                    </a>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </CardContent>
                                                </Card>

                                                <div className="h-px bg-gray-200" />

                                                <Card className="shadow-none border-none">
                                                    <CardContent className="p-4">
                                                        <div className="space-y-2">
                                                            <div className="flex items-center">
                                                                <span className="text-sm font-medium text-green-600">
                                                                    {submissionEmail?.previous_email_body_html?.match(/Patricia Guarino|Patty Guarino|Erica Bazzy/)?.[0] ||
                                                                     submissionEmail?.title?.split(' - ')[0] || "User"}
                                                                </span>
                                                                <span className="text-xs text-gray-500 ml-2">
                                                                    {submissionEmail?.previous_email_body_html?.match(/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/)?.[0] ||
                                                                     submissionEmail?.title?.split(' - ')[1] || ""}
                                                                </span>
                                                            </div>
                                                            <div className="text-sm text-gray-700">
                                                                {submissionEmail?.previous_email_body_html && (
                                                                    <div className="space-y-4">
                                                                        {submissionEmail.previous_email_body_html
                                                                            .replace(/<style[\s\S]*?<\/style>/gi, '')
                                                                            .replace(/<head[\s\S]*?<\/head>/gi, '')
                                                                            .replace(/&nbsp;|&#160;/g, ' ')
                                                                            .replace(/&amp;/g, '&')
                                                                            .replace(/&lt;/g, '<')
                                                                            .replace(/&gt;/g, '>')
                                                                            .replace(/&#\d+;/g, '')
                                                                            .replace(/<a\s+href="mailto:[^"]+"\s*[^>]*>([^<]+)<\/a>/gi, '$1')
                                                                            .replace(/mailto:/g, '')
                                                                            .replace(/<br\s*\/?>/gi, '\n')
                                                                            .replace(/<\/?[^>]+(>|$)/g, '')
                                                                            .split('\n')
                                                                            .filter(line => {
                                                                                const trimmedLine = line.trim();
                                                                                return trimmedLine &&
                                                                                    !trimmedLine.includes('From:') &&
                                                                                    !trimmedLine.includes('Sent:') &&
                                                                                    !trimmedLine.includes('To:') &&
                                                                                    !trimmedLine.includes('Cc:') &&
                                                                                    !trimmedLine.includes('Subject:') &&
                                                                                    !trimmedLine.includes('CAUTION:') &&
                                                                                    !trimmedLine.includes('Disclaimer') &&
                                                                                    !trimmedLine.includes('This email has been scanned') &&
                                                                                    !trimmedLine.includes('Click Here') &&
                                                                                    !trimmedLine.includes('Millennial Specialty Insurance') &&
                                                                                    !trimmedLine.includes('McGowan Wholesale') &&
                                                                                    !trimmedLine.includes('Marketing Broker') &&
                                                                                    !trimmedLine.includes('www.') &&
                                                                                    !trimmedLine.match(/^[cp]:\s*[\d-]+$/i) &&
                                                                                    !trimmedLine.includes('External Message') &&
                                                                                    !trimmedLine.includes('Thank you!') &&
                                                                                    !trimmedLine.includes('Thanks!') &&
                                                                                    !trimmedLine.includes('Note:') &&
                                                                                    !trimmedLine.includes('habsubmissions@') &&
                                                                                    !trimmedLine.includes('The information contained in this communication') &&
                                                                                    !trimmedLine.includes('target="_blank"')
                                                                            })
                                                                            .reduce((acc, line) => {
                                                                                // Skip repeated empty lines and common signature lines
                                                                                if (
                                                                                    (line.trim() === '' && acc[acc.length - 1]?.trim() === '') ||
                                                                                    line.includes('Underwriting Analyst') ||
                                                                                    line.includes('Commercial Risk') ||
                                                                                    line.includes('LinkedIn') ||
                                                                                    line.includes('msimga.com') ||
                                                                                    /^[A-Za-z\s]+,?$/.test(line.trim()) ||
                                                                                    /^c:\s*\d{3}-\d{3}-\d{4}$/.test(line.trim()) ||
                                                                                    /^mailto:/.test(line.trim())
                                                                                ) {
                                                                                    return acc;
                                                                                }
                                                                                return [...acc, line];
                                                                            }, [] as string[])
                                                                            .map((line, index) => (
                                                                                <p key={index} className="text-gray-700">
                                                                                    {line.trim()}
                                                                                </p>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                )}
                                                            </div>


                                                            {submissionEmail?.previous_email_attachment_blobs &&
                                                             submissionEmail.previous_email_attachment_blobs.length > 0 && (
                                                                <div className="flex flex-wrap gap-2">
                                                                    {submissionEmail.previous_email_attachment_blobs.map((document) => (
                                                                        <a
                                                                            key={document.name}
                                                                            href={document.url}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            className="inline-flex items-center text-sm text-gray-700 bg-white border border-gray-200 rounded-md px-3 py-1.5 hover:bg-gray-100"
                                                                        >
                                                                            <div className="flex items-center space-x-2">
                                                                                <div className="w-7 h-7 rounded-md bg-purple-50 flex items-center justify-center">
                                                                                    <FileText className="w-3 h-3 text-purple-600" />
                                                                                </div>
                                                                                <span className="text-xs text-gray-900 truncate max-w-[180px]">
                                                                                    {document.name}
                                                                                </span>
                                                                            </div>
                                                                        </a>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </CardContent>
                                                </Card>
                                            </div>
                                        </ScrollArea>
                                    </div>
                                </div>
                            ) : null}
                        </div>

                        <div className="w-80">
                            <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden h-[calc(100vh-105px)]">
                                <SubmissionAIChat />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
})

function SubmissionDetailSkeleton() {
    return (
        <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden mb-4">
            <div className="p-4">
                <div className="h-8 w-64 bg-gray-200 rounded mb-6 animate-pulse"></div>
                <div className="h-64 w-full bg-gray-200 rounded animate-pulse"></div>
            </div>
        </div>
    );
}

function SubmissionDetailError({ error }: { error: string }) {
    return (
        <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden mb-4">
            <div className="p-4">
                <h1 className="text-xl font-semibold mb-6">Error</h1>
                <p className="text-red-500 mb-4">{error}</p>
            </div>
        </div>
    );
}
