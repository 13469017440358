import { useState, useMemo, useEffect, useCallback } from "react"
import { Button } from "../ui/button"
import { Search, ChevronUp, ChevronDown, ChevronLeft, ChevronRight, User, Calendar, DollarSign, Plus, ExternalLink } from "lucide-react"
import { HomeSidebarV1 } from "../HomeSidebarV1"
import { Input } from "../ui/input"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table"
import { Checkbox } from "../ui/checkbox"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select"
import { useNavigate } from 'react-router-dom'
import { withRequiredAuthInfo } from "@propelauth/react";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
} from "../ui/dropdown-menu"
import { useNodeApi } from "../../hooks/useNodeApi";
import { SubmissionData } from "../../services/api";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../ui/popover"
import { Slider } from "../ui/slider"

type SubmissionEntry = {
  id: string;
  sender: string;
  dateProcessed: string;
  effectiveDate: string;
  submissionName: string;
  totalInsured: number;
  controlledBusiness: string;
  priceTarget: string;
  ingestionSpreadsheetUrl: string;
  lossRunSpreadsheetUrl: string;
};

const parsePrice = (value: string): number => {
  const cleanValue = value.replace(/[^0-9]/g, '');
  return parseInt(cleanValue, 10) || 0;
};

export const Submissions = withRequiredAuthInfo(({ accessToken }: { accessToken: string | null }) => {
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortConfig, setSortConfig] = useState<{ key: keyof SubmissionEntry; direction: 'asc' | 'desc' | null }>({
    key: 'dateProcessed',
    direction: 'desc'
  });
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [senders, setSenders] = useState<string[]>([]);
  const [selectedSenders, setSelectedSenders] = useState<string[]>([]);
  const { fetchSubmissions } = useNodeApi(accessToken);
  const [submissions, setSubmissions] = useState<SubmissionEntry[]>([]);
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  const columnWidths = {
    checkbox: "w-[25px]",
    sender: "w-[110px]",
    dateProcessed: "w-[130px]",
    effectiveDate: "w-[120px]",
    submissionName: "w-[140px]",
    totalInsured: "w-[120px]",
    controlledBusiness: "w-[160px]",
    priceTarget: "w-[110px]",
    ingestion: "w-[90px]",
    lossRun: "w-[95px]"
  } as const;

  const [priceRange, setPriceRange] = useState<[number, number]>([0, 500000000]);


  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleRowSelection = (id: string, isChecked: boolean) => {
    setSelectedRows(prev =>
      isChecked
        ? [...prev, id]
        : prev.filter(rowId => rowId !== id)
    );
  };

  const handleSelectAllRows = (checked: boolean) => {
    if (checked) {
      const newSelectedRows = selectedRows.slice();
      paginatedData.forEach(row => {
        if (!newSelectedRows.includes(row.id)) {
          newSelectedRows.push(row.id);
        }
      });
      setSelectedRows(newSelectedRows);
    } else {
      const currentPageIds = paginatedData.map(row => row.id);
      setSelectedRows(selectedRows.filter(id => !currentPageIds.includes(id)));
    }
  };

  const requestSort = (key: keyof SubmissionEntry) => {
    setSortConfig((currentConfig) => {
      if (currentConfig.key === key) {
        if (currentConfig.direction === 'asc') {
          return { key, direction: 'desc' };
        }
        if (currentConfig.direction === 'desc') {
          return { key, direction: null };
        }
      }
      return { key, direction: 'asc' };
    });
  };

  const getSubmissions = useCallback(async () => {
    const result = await fetchSubmissions();
    const submissions = result.map((submission: SubmissionData) => {
      const totalInsured = parseInt(submission.data.total_insured_value || "");
      return {
        id: submission.submission_id,
        sender: submission.data.sender_name || "",
        dateProcessed: submission.data.date_processed || "",
        effectiveDate: submission.data.effective_date || "",
        submissionName: submission.data.submission_name || "",
        totalInsured: isNaN(totalInsured) ? 0 : totalInsured,
        controlledBusiness: submission.data.is_controlled_business_str || "Not found",
        priceTarget: submission.data.price_target || "Not found",
        ingestionSpreadsheetUrl: submission.data.ingestion_spreadsheet_path || "#",
        lossRunSpreadsheetUrl: submission.data.loss_run_spreadsheet_path || "#"
      }
    });
    return submissions;
  }, [fetchSubmissions]);

  useEffect(() => {
    let ignore = false;
    setIsLoading(true);
    getSubmissions().then(result => {
      if (!ignore) {
        setSenders(Array.from(new Set(result.map(submission => submission.sender))));
        setSubmissions(result);
        setIsLoading(false);
      }
    });
    return () => {
      ignore = true;
    };
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  const sortedAndFilteredData = useMemo(() => {
    return submissions
      .filter((item) => {
        if (item.totalInsured < priceRange[0] || item.totalInsured > priceRange[1]) {
          return false;
        }

        if (selectedSenders.length > 0 && !selectedSenders.includes(item.sender)) {
          return false;
        }

        const searchLower = searchQuery.toLowerCase();
        return (
          item.sender.toLowerCase().includes(searchLower) ||
          item.dateProcessed.toLowerCase().includes(searchLower) ||
          item.effectiveDate.toLowerCase().includes(searchLower) ||
          item.submissionName.toLowerCase().includes(searchLower) ||
          item.totalInsured.toString().includes(searchQuery) ||
          item.controlledBusiness.toLowerCase().includes(searchLower) ||
          item.priceTarget.toString().includes(searchQuery)
        );
      })
      .sort((a, b) => {
        if (sortConfig.direction === null) return 0;
        if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
        if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      });
  }, [submissions, searchQuery, sortConfig, selectedSenders, priceRange]);

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    return sortedAndFilteredData.slice(startIndex, startIndex + rowsPerPage);
  }, [sortedAndFilteredData, currentPage, rowsPerPage]);

  const SortableTableHead = ({ children, sortKey, className }: {
    children: React.ReactNode,
    sortKey: keyof SubmissionEntry,
    className?: string
  }) => (

    <TableHead className={`border-y border-gray-200 ${className} px-4`}>

      <Button
        variant="ghost"
        onClick={() => requestSort(sortKey)}
        className="hover:bg-transparent w-full justify-start -ml-4 px-4 h-full whitespace-nowrap"
        disabled={isLoading}
      >
        <span>{children}</span>
        {sortConfig.key === sortKey && (
          <span className="ml-2">
            {sortConfig.direction === 'asc' ?
              <ChevronUp className="h-4 w-4" /> :
              <ChevronDown className="h-4 w-4" />
            }
          </span>
        )}
      </Button>
    </TableHead>
  );

  const handleRowClick = (submission: SubmissionEntry) => {
    navigate(`/submissions/${submission.id}`)
  }

  const renderShimmerRows = () => {
    return Array(rowsPerPage).fill(0).map((_, index) => (
      <TableRow key={`shimmer-${index}`}>
        <TableCell className={`border-y border-l border-gray-200 ${columnWidths.checkbox}`}>
          <div className="h-4 w-4 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className={`border-y border-gray-200 ${columnWidths.sender}`}>
          <div className="h-4 w-24 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className={`border-y border-gray-200 ${columnWidths.dateProcessed}`}>
          <div className="h-4 w-28 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className={`border-y border-gray-200 ${columnWidths.effectiveDate}`}>
          <div className="h-4 w-28 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className={`border-y border-gray-200 ${columnWidths.submissionName}`}>
          <div className="h-4 w-32 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className={`border-y border-gray-200 ${columnWidths.totalInsured}`}>
          <div className="h-4 w-24 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className={`border-y border-gray-200 ${columnWidths.controlledBusiness}`}>
          <div className="h-4 w-32 bg-gray-200 rounded animate-pulse"></div>

        </TableCell>
        <TableCell className={`border-y border-gray-200 ${columnWidths.priceTarget}`}>
          <div className="h-4 w-24 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className={`border-y border-gray-200 ${columnWidths.ingestion}`}>
          <div className="h-4 w-4 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className={`border-y border-r border-gray-200 ${columnWidths.lossRun}`}>
          <div className="h-4 w-4 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
      </TableRow>
    ));
  };

  const formatPrice = (value: number) => {
    return currencyFormatter.format(value);
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <HomeSidebarV1 />
      <div className="flex-1 flex flex-col overflow-hidden ml-56">
        <div className="flex-1 overflow-y-auto bg-gray-50 py-8">
          <div className="max-w-6xl mx-auto px-2">
            <div>
              <div className="flex justify-between items-center mb-6">
                <h1 className="text-xl font-semibold">Submissions</h1>
                <div className="flex items-center gap-3">
                  <Button
                    variant="outline"
                  >
                    Preview workflow
                  </Button>
                  <Button>
                    <Plus className="h-4 w-4 mr-2" />
                    Create new submission
                  </Button>
                </div>
              </div>

              <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden mb-4">
                <div className="p-4">
                  <div className="flex items-center space-x-2 mb-4">
                    <div className="relative">
                      <Input
                        type="text"
                        placeholder="Filter submissions..."
                        className="pl-8 pr-4 py-1 w-48 bg-white border-gray-300 text-gray-900 focus:ring-0 h-9"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                      <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                    </div>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button
                          variant="outline"
                          size="sm"
                          className="bg-white"
                        >
                          <User className="h-4 w-4 mr-2" />
                          Sender
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent
                        className="w-[280px] bg-white p-2 shadow-lg rounded-md border border-gray-200"
                        align="start"
                      >
                        <div className="relative mb-2">
                          <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                          <Input
                            type="text"
                            placeholder="Search for a sender"
                            className="pl-8 pr-2 py-1 w-full h-9 bg-white border-gray-200 text-sm focus-visible:ring-0"
                            value={searchQuery}
                            onChange={handleSearchChange}
                          />
                        </div>
                        {senders
                          .filter(sender =>
                            sender.toLowerCase().includes(searchQuery.toLowerCase())
                          )
                          .map((sender) => (
                            <div
                              key={sender}
                              className="flex items-center space-x-3 px-2 py-1.5 hover:bg-gray-50 cursor-pointer"
                              onClick={() => {
                                setSelectedSenders(prev =>
                                  prev.includes(sender)
                                    ? prev.filter(s => s !== sender)
                                    : [...prev, sender]
                                );
                              }}
                            >
                              <Checkbox
                                checked={selectedSenders.includes(sender)}
                                className="h-4 w-4 rounded-sm border-gray-300 data-[state=checked]:bg-black data-[state=checked]:border-black"
                              />
                              <span className="text-sm text-gray-900">{sender}</span>
                            </div>
                          ))}
                      </DropdownMenuContent>
                    </DropdownMenu>
                    <Button
                      variant="outline"
                      size="sm"
                    >
                      <Calendar className="h-4 w-4 mr-2" />
                      Date processed
                    </Button>
                    <Popover>
                      <PopoverTrigger asChild>
                        <Button variant="outline" size="sm" className="bg-white">

                          <DollarSign className="h-4 w-4 mr-2" />
                          Total insured
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-[400px] p-4 bg-white" align="start">
                        <div className="space-y-5">
                          <h4 className="font-medium leading-none">Price range</h4>
                          <Slider
                            defaultValue={[0, 500000000]}
                            max={500000000}
                            value={priceRange}
                            onValueChange={(value) => setPriceRange(value as [number, number])}
                            className="my-6"
                          />
                          <div className="flex items-center gap-2">
                            <Input
                              type="text"
                              value={formatPrice(priceRange[0])}
                              onChange={(e) => {
                                const value = parsePrice(e.target.value);
                                if (value >= 0 && value <= priceRange[1]) {
                                  setPriceRange([value, priceRange[1]]);
                                }
                              }}
                              className="h-9"
                            />
                            <span className="text-sm text-gray-500">to</span>
                            <Input
                              type="text"
                              value={formatPrice(priceRange[1])}
                              onChange={(e) => {
                                const value = parsePrice(e.target.value);
                                if (value >= priceRange[0] && value <= 500000000) {
                                  setPriceRange([priceRange[0], value]);
                                }
                              }}
                              className="h-9"
                            />
                          </div>
                        </div>
                      </PopoverContent>
                    </Popover>
                  </div>

                  <div className="overflow-x-auto rounded-md border">
                    <Table className="border border-gray-200 table-fixed w-full">
                      <TableHeader>
                        <TableRow className=" border-b border-gray-200">
                          <TableHead className={`${columnWidths.checkbox}`}>
                            <Checkbox
                              checked={paginatedData.length > 0 && paginatedData.every(row => selectedRows.includes(row.id))}
                              onCheckedChange={handleSelectAllRows}
                            />
                          </TableHead>
                          <SortableTableHead sortKey="sender" className={columnWidths.sender}>Sender</SortableTableHead>
                          <SortableTableHead sortKey="dateProcessed" className={columnWidths.dateProcessed}>Date Processed</SortableTableHead>
                          <SortableTableHead sortKey="effectiveDate" className={columnWidths.effectiveDate}>Effective Date</SortableTableHead>
                          <SortableTableHead sortKey="submissionName" className={columnWidths.submissionName}>Submission Name</SortableTableHead>
                          <SortableTableHead sortKey="totalInsured" className={columnWidths.totalInsured}>Total Insured</SortableTableHead>
                          <SortableTableHead sortKey="controlledBusiness" className={columnWidths.controlledBusiness}>Controlled Business</SortableTableHead>
                          <SortableTableHead sortKey="priceTarget" className={columnWidths.priceTarget}>Price Target</SortableTableHead>
                          <SortableTableHead sortKey="ingestionSpreadsheetUrl" className={columnWidths.ingestion}>Ingestion</SortableTableHead>
                          <SortableTableHead sortKey="lossRunSpreadsheetUrl" className={columnWidths.lossRun}>Loss Run</SortableTableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {isLoading ? (
                          renderShimmerRows()
                        ) : (
                          paginatedData.map((row, idx) => (
                            <TableRow
                              key={row.id}
                              className={`cursor-pointer hover:bg-gray-50 ${idx === paginatedData.length - 1 ? 'rounded-b-lg' : 'border-b border-gray-200'}`}
                              onClick={() => handleRowClick(row)}
                            >
                              <TableCell className="pl-4">
                                <Checkbox
                                  checked={selectedRows.includes(row.id)}
                                  onCheckedChange={(checked) => handleRowSelection(row.id, checked as boolean)}
                                  onClick={(e) => e.stopPropagation()}
                                />
                              </TableCell>
                              <TableCell className={`${columnWidths.sender} truncate ...`}>{row.sender}</TableCell>
                              <TableCell className={columnWidths.dateProcessed}>{row.dateProcessed}</TableCell>
                              <TableCell className={columnWidths.effectiveDate}>{row.effectiveDate}</TableCell>
                              <TableCell className={`${columnWidths.submissionName} truncate ...`}>{row.submissionName}</TableCell>
                              <TableCell className={columnWidths.totalInsured}>{currencyFormatter.format(row.totalInsured)}</TableCell>
                              <TableCell className={columnWidths.controlledBusiness}>{row.controlledBusiness}</TableCell>
                              <TableCell className={`${columnWidths.priceTarget} truncate ...`}>{row.priceTarget}</TableCell>
                              <TableCell className={columnWidths.ingestion} onClick={(e) => e.stopPropagation()}>
                                <a href={row.ingestionSpreadsheetUrl} target="_blank" rel="noopener noreferrer">
                                  <ExternalLink className="h-4 w-4" />
                                </a>
                              </TableCell>
                              <TableCell className={columnWidths.lossRun} onClick={(e) => e.stopPropagation()}>
                                <a href={row.lossRunSpreadsheetUrl} target="_blank" rel="noopener noreferrer">
                                  <ExternalLink className="h-4 w-4" />
                                </a>
                              </TableCell>
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </div>

                  <div className="flex justify-between items-center mt-4">
                    <p className="text-sm text-gray-500">
                      {isLoading
                        ? <div className="h-4 w-32 bg-gray-200 rounded animate-pulse"></div>
                        : `${selectedRows.length} of ${sortedAndFilteredData.length} row(s) selected.`
                      }
                    </p>
                    <div className="flex items-center space-x-6 lg:space-x-8">
                      <div className="flex items-center space-x-2">
                        <span className="text-sm font-medium text-zinc-800">Rows per page</span>
                        <Select value={rowsPerPage.toString()} onValueChange={(value) => setRowsPerPage(Number(value))}>
                        <SelectTrigger className="w-[70px] focus:ring-0 bg-white">
                          <SelectValue placeholder={rowsPerPage.toString()} />
                        </SelectTrigger>
                        <SelectContent className="bg-white">
                          <SelectItem value="5">5</SelectItem>
                          <SelectItem value="10">10</SelectItem>
                          <SelectItem value="20">20</SelectItem>
                          </SelectContent>
                        </Select>
                      </div>
                      <span className="text-sm font-medium text-zinc-800">
                        Page {currentPage} of {Math.ceil(sortedAndFilteredData.length / rowsPerPage)}
                      </span>
                      <div className="flex space-x-1">
                        <Button
                          variant="outline"
                          className="h-8 w-8 p-0"
                          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                          disabled={currentPage === 1}
                        >
                          <ChevronLeft className="h-4 w-4" />
                        </Button>
                        <Button
                          variant="outline"
                          className="h-8 w-8 p-0"
                          onClick={() => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(sortedAndFilteredData.length / rowsPerPage)))}
                          disabled={currentPage === Math.ceil(sortedAndFilteredData.length / rowsPerPage)}
                        >
                          <ChevronRight className="h-4 w-4" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
});
