import { useState, useCallback, useRef, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useNodeApi } from "../../hooks/useNodeApi";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from "rehype-raw";
import { CompareLogResponse, CitationWithState } from "../../services/api";
import { Button } from "../ui/button";
import { HomeSidebarV1 } from "../HomeSidebarV1";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";
import { ExternalLink, Search, ChevronRight, FileText, FileStack } from "lucide-react";
import toast from 'react-hot-toast';
import { Input } from "../ui/input";
import { PDFPreviewSlider } from "./PDFPreviewSlider";
import { produce } from "immer";
import { withRequiredAuthInfo } from "@propelauth/react";
import axios from 'axios';
import { ScrollArea } from "../ui/scroll-area";
import { usePolling } from "../../lib/usePolling";
import { POLLING_CONFIG } from "../../config/pollingConfig";
import DocumentPreviewDialog from '../DocumentPreviewDialog';
import { SideBySideComparison } from "./SideBySideComparison";

type Status = 'Match' | 'Mismatch';

const StatusBadge = ({ status }: { status: Status }) => {
  const colorClass = status === 'Match' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800';
  return (
    <span className={`px-2 py-1 ${colorClass} rounded-full text-xs font-medium`}>
      {status}
    </span>
  );
};

export const CompareDetail = withRequiredAuthInfo(({ accessToken }: { accessToken: string | null }) => {
  const navigate = useNavigate();
  const { id: compareLogId } = useParams<{ id: string }>();
  const { getCompareLog, getPdfUrl, exportCompareLog } = useNodeApi(accessToken);
  const [compareLog, setCompareLog] = useState<CompareLogResponse | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const lastPollTimeRef = useRef(0);
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [pdfUrls, setPdfUrls] = useState<Map<number, string>>(new Map());
  const [currentPdfUrl, setCurrentPdfUrl] = useState<string | null>(null);
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [selectedCitations, setSelectedCitations] = useState<CitationWithState[]>([]);
  const [showFullSummary, setShowFullSummary] = useState(false);
  const [filterQuery, setFilterQuery] = useState("");
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [previewFileType, setPreviewFileType] = useState<'pdf' | 'image'>('pdf');
  const [isSideBySideOpen, setIsSideBySideOpen] = useState(false);
  const [sideBySidePdfUrls, setSideBySidePdfUrls] = useState<{ left: string | null; right: string | null }>({
    left: null,
    right: null,
  });


  const filteredRows = useMemo(() => {
    if (!compareLog?.result?.rows) return [];

    const query = filterQuery.toLowerCase().trim();
    if (!query) return compareLog.result.rows;

    return compareLog.result.rows.filter(row => {
      if (row.title.toLowerCase().includes(query)) return true;

      if (row.data.some(value =>
        value && value.toString().toLowerCase().includes(query)
      )) return true;

      return false;
    });
  }, [compareLog?.result?.rows, filterQuery]);

  usePolling(
    () => {
      if (!compareLogId) {
        throw new Error('No comparison ID provided.');
      }
      return getCompareLog(compareLogId);
    },
    {
      interval: POLLING_CONFIG.INITIAL_INTERVAL,
      maxInterval: POLLING_CONFIG.MAX_INTERVAL,
      backoffMultiplier: POLLING_CONFIG.BACKOFF_MULTIPLIER,
      enabled: true,
      lastPollTimeRef,
      continuePollingOnSuccess: true,
      isComplete: (result) => {
        const isComplete = Boolean(
          result?.result?.header?.length &&
          result?.result?.rows?.length &&
          result?.result?.summary
        );
        return isComplete;
      },
      onSuccess: (result) => {
        setCompareLog(result);
        setError(null);
        setIsLoading(false);
      },
      onError: (error) => {
        setIsLoading(false);
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          navigate('/404');
          return false;
        }
        setError(error instanceof Error ? error.message : 'Unknown error');
        return true;
      },
      onOfflineChange: (isOffline) => {
        if (isOffline) {
          toast.error("Failed to fetch comparison results. Please try again.");
        } else {
          toast.success("Connection restored. Compare log updated.");
        }
      }
    }
  );

  const handleExport = async () => {
    if (!compareLog) return;

    setIsExporting(true);
    try {
      const downloadUrl = await exportCompareLog(compareLog._id);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.target = '_blank';
      link.download = `comparison_${compareLog._id}.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success('Export successful. Your download should begin shortly.');
    } catch (error) {
      console.error('Export failed:', error);
      toast.error('Failed to export comparison. Please try again.');
    } finally {
      setIsExporting(false);
    }
  };

  const handleSideBySide = async () => {
    if (!compareLog?.request?.documents || compareLog.request.documents.length < 2) {
      toast.error('Not enough documents for comparison');
      return;
    }

    try {
      const [leftUrl, rightUrl] = await Promise.all([
        getPdfUrl(compareLog.request.documents[0]),
        getPdfUrl(compareLog.request.documents[1])
      ]);

      setSideBySidePdfUrls({ left: leftUrl, right: rightUrl });
      setIsSideBySideOpen(true);
    } catch (error) {
      console.error('Failed to load PDFs:', error);
      toast.error('Failed to load PDFs for comparison');
    } finally {
      setIsLoading(false);
    }
  };

  const extractCitationsWithStates = (
    compareLog: CompareLogResponse,
    rowIndex: number,
    colIndex: number
): CitationWithState[] => {
    const rawCitations = compareLog?.result?.rows[rowIndex]?.citations?.[colIndex] || [];
    const state: 'match' | 'mismatch' = compareLog.result.rows[rowIndex].num_mismatches > 0 ? 'mismatch' : 'match';

    if (Array.isArray(rawCitations)) {
        return rawCitations.map((citation) => ({
            citation,
            state,
        }));
    } else if (typeof rawCitations === 'object') {
        return Object.values(rawCitations).flat().map((citation) => ({
            citation,
            state,
        }));
    }

    return [];
};

const handleCellClick = useCallback(async (rowIndex: number, colIndex: number) => {
  if (!compareLog) {
      console.error("CompareLog is null");
      return;
  }

  setIsSliderOpen(true);
  setIsLoadingPdf(true);
  setCurrentPdfUrl(null);
  setSelectedCitations([]);

  try {
      const citationsWithState: CitationWithState[] = extractCitationsWithStates(compareLog, rowIndex, colIndex);
      setSelectedCitations(citationsWithState);

      if (!pdfUrls.has(colIndex)) {
          const documentId = compareLog.request?.documents?.[colIndex];
          if (documentId) {
              const url = await getPdfUrl(documentId);
              setPdfUrls(produce(draft => {
                  draft.set(colIndex, url);
              }));
              setCurrentPdfUrl(url);
          } else {
              console.warn('Document ID not found for column:', colIndex);
          }
      } else {
          setCurrentPdfUrl(pdfUrls.get(colIndex) || null);
      }
  } catch (error) {
      console.error("Error handling cell click:", error);
      toast.error("Failed to load PDF preview. Please try again.");
  } finally {
      setIsLoadingPdf(false);
    }
  }, [compareLog, getPdfUrl, pdfUrls]);

  const handleDocumentClick = async (documentId: string) => {
    try {
      const url = await getPdfUrl(documentId);
      setPreviewFileType('pdf');
      setPreviewUrl(url);
      setIsPreviewOpen(true);
    } catch (error) {
      console.error("Failed to fetch PDF URL:", error);
      toast.error("Failed to load PDF preview");
    }
  };

  const handleSideBySideCardClick = useCallback((rowIndex: number, colIndex: number) => {
    if (!compareLog) {
      console.error("CompareLog is null");
      return;
    }

    const citationsWithState = extractCitationsWithStates(compareLog, rowIndex, colIndex);
    setSelectedCitations(citationsWithState);
  }, [compareLog]);

  if (isLoading) {
    return <CompareDetailSkeleton />;
  }

  if (error) {
    return <CompareDetailError error={error} />;
  }

  if (!compareLog || !compareLog.result) {
    return <CompareDetailSkeleton />;
  }

  const { header, rows, summary } = compareLog.result;

  if (!header || !rows) {
    return <CompareDetailError error="Invalid comparison data structure." />;
  }

  return (
    <>
      <div className="flex h-screen overflow-hidden">
        <HomeSidebarV1 />
        <div className="flex-1 overflow-auto min-h-screen bg-white py-2 ml-56">
          <div className="mx-auto px-2">
            <div className="bg-white h-full overflow-y-auto">
              <div className="p-6">
                <div>
                  <div className="flex items-center justify-between mb-6">
                    <div className="flex items-center text-md text-gray-500">
                      <span className="hover:text-gray-700 cursor-pointer" onClick={() => navigate('/compare')}>Comparison AI</span>
                      <ChevronRight className="h-4 w-4 mx-2" />
                      <span className="font-medium text-gray-900">Details</span>
                    </div>
                  </div>

                  <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center space-x-2">
                      <div className="relative">
                        <Input
                          type="text"
                          placeholder="Filter documents..."
                          className="pl-8 pr-4 py-1 w-48 h-9"
                          value={filterQuery}
                          onChange={(e) => setFilterQuery(e.target.value)}
                        />
                        <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                      </div>
                    </div>
                    <div className="flex items-center space-x-2">
                    <Button
                      variant="outline"
                      size="sm"
                      className="text-gray-700 hover:text-gray-900 border border-gray-300 hover:bg-white disabled:opacity-50 disabled:cursor-not-allowed"
                      onClick={handleSideBySide}
                      disabled={!compareLog?.request?.documents || compareLog.request.documents.length !== 2}
                      title={
                        !compareLog?.request?.documents ? 'No documents available' :
                        compareLog.request.documents.length < 2 ? 'Need at least 2 documents to compare' :
                        compareLog.request.documents.length > 2 ? 'Side by side comparison only supports 2 documents' :
                        'Compare documents side by side'
                      }
                    >
                      <FileStack className="h-4 w-4 mr-2" />
                      Side by side comparison
                    </Button>
                    <Button
                      variant="outline"
                      size="sm"
                      className="text-gray-700 hover:text-gray-900 border border-gray-300 hover:bg-white"
                      onClick={handleExport}
                      disabled={isExporting}
                    >
                      <ExternalLink className="h-4 w-4 mr-2" />
                      {isExporting ? 'Exporting...' : 'Export'}
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="mb-6">
                  <h3 className="text-lg font-semibold mb-4">Uploaded documents</h3>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {compareLog.request.documents.map((doc, index) => (
                      <div
                        key={index}
                        onClick={() => handleDocumentClick(doc)}
                        className="flex items-center p-4 rounded-lg border border-gray-200 bg-white hover:bg-gray-50 cursor-pointer"
                      >
                        <div className="w-8 h-8 rounded-full bg-purple-100 flex items-center justify-center mr-3">
                          <FileText className="w-4 h-4 text-purple-600" />
                        </div>
                        <div className="flex flex-col">
                          <span className="text-sm font-medium text-gray-900">
                            {header[index]}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <h3 className="text-lg font-semibold mb-4">Summary</h3>
                <div className="mb-6 border border-gray-200 rounded-lg">
                  <div className="p-4 pt-0 relative">
                    <ScrollArea
                      className={`
                        relative
                        ${!showFullSummary ? 'max-h-32' : 'h-[calc(100vh-300px)]'}
                        transition-all
                        duration-300
                      `}
                      type="always"
                    >
                      <div className="pr-4">
                        <ReactMarkdown
                          remarkPlugins={[remarkGfm]}
                          rehypePlugins={[rehypeRaw]}
                          components={{
                            table: (props) => (
                              <table className="border-collapse border border-gray-200 my-4 w-full text-gray-900" {...props} />
                            ),
                            th: (props) => (
                              <th className="border border-gray-200 px-4 py-2 bg-gray-50 font-semibold text-gray-900" {...props} />
                            ),
                            td: (props) => (
                              <td className="border border-gray-200 px-4 py-2 text-gray-700" {...props} />
                            ),
                            h1: (props) => <h1 className="text-xl font-semibold mt-5 mb-3 text-gray-900" {...props} />,
                            h2: (props) => <h2 className="text-xl font-semibold mt-5 mb-3 text-gray-900" {...props} />,
                            h3: (props) => <h3 className="text-xl font-medium mt-4 mb-2 text-gray-900" {...props} />,
                            p: (props) => <p className="my-2 text-gray-700" {...props} />,
                            ul: (props) => <ul className="list-disc pl-5 my-3 text-gray-700" {...props} />,
                            ol: (props) => <ol className="list-decimal pl-5 my-3 text-gray-700" {...props} />,
                            li: (props) => <li className="my-1 text-gray-700" {...props} />,
                            blockquote: (props) => (
                              <blockquote className="border-l-4 border-gray-300 pl-4 py-2 my-4 italic text-gray-600" {...props} />
                            ),
                            code: (props) => {
                              const { children, className } = props
                              const match = /language-(\w+)/.exec(className || '')
                              return match ? (
                                <pre className="bg-gray-50 rounded p-2 overflow-x-auto my-4">
                                  <code className={`${className} text-gray-700`} {...props}>
                                    {children}
                                  </code>
                                </pre>
                              ) : (
                                <code className="bg-gray-50 rounded px-1 py-0.5 font-mono text-sm text-gray-700" {...props}>
                                  {children}
                                </code>
                              )
                            },
                            pre: (props) => <pre className="bg-gray-50 rounded p-2 overflow-x-auto my-4 text-gray-700" {...props} />,
                            a: (props) => <a className="text-blue-600 hover:underline" {...props} />,
                            img: (props) => <img className="max-w-full h-auto my-4" {...props} />,
                          }}
                        >
                          {summary || "No summary available"}
                        </ReactMarkdown>
                      </div>

                      {!showFullSummary && (
                        <div className="absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-t from-white to-transparent pointer-events-none z-10" />
                      )}
                    </ScrollArea>

                    <div className="absolute bottom-0 left-0 right-0 flex justify-center z-20">
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => setShowFullSummary(!showFullSummary)}
                        className="bg-white border border-gray-200 shadow-sm px-4 py-2 rounded-full transform translate-y-1/2 hover:bg-gray-50"
                      >
                        {showFullSummary ? 'Show less' : 'Show more'}
                      </Button>
                    </div>
                  </div>
                </div>

                <h2 className="text-lg font-semibold mb-4">Table</h2>
                <Table className="border-collapse border border-gray-200">
                  <TableHeader>
                    <TableRow>
                      <TableHead className="border-y border-gray-200">Schema Fields</TableHead>
                      {header.map((docName, index) => (
                        <TableHead key={index} className="border border-gray-200">
                          {docName}
                        </TableHead>
                      ))}
                      <TableHead className="w-[100px] border-y border-r border-gray-200">Status</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {filteredRows.map((row, rowIndex) => (
                      <TableRow key={rowIndex}>
                        <TableCell className="border-y border-gray-200">{row.title}</TableCell>
                        {row.data.map((value, colIndex) => (
                          <TableCell
                            key={colIndex}
                            className="border border-gray-200 relative cursor-pointer hover:bg-gray-50"
                            onClick={() => handleCellClick(rowIndex, colIndex)}
                          >
                            {value}
                            <ChevronRight className="h-4 w-4 absolute right-2 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity" />
                          </TableCell>
                        ))}
                        <TableCell className="border-y border-gray-200">
                          <StatusBadge status={row.num_mismatches === 0 ? 'Match' : 'Mismatch'} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </div>
        <PDFPreviewSlider
          isOpen={isSliderOpen}
          onClose={() => {
            setIsSliderOpen(false);
            setCurrentPdfUrl(null);
            setSelectedCitations([]);
          }}
          pdfUrl={currentPdfUrl}
          citations={selectedCitations}
          isLoading={isLoadingPdf}
        />
        <DocumentPreviewDialog
          isOpen={isPreviewOpen}
          onClose={() => {
            setPreviewUrl("");
            setIsPreviewOpen(false);
          }}
          previewUrl={previewUrl}
          fileType={previewFileType}
        />
        {compareLog && (
          <SideBySideComparison
            isOpen={isSideBySideOpen}
            onClose={() => {
              setIsSideBySideOpen(false);
              setSelectedCitations([]);
            }}
            leftPdfUrl={sideBySidePdfUrls.left}
            rightPdfUrl={sideBySidePdfUrls.right}
            citations={selectedCitations}
            rows={compareLog.result?.rows || []}
            onCardClick={handleSideBySideCardClick}
            extractCitations={extractCitationsWithStates}
            compareLog={compareLog}
          />
        )}
      </div>
    </>
  );
})

function CompareDetailSkeleton() {
  const navigate = useNavigate();
  return (
    <div className="flex h-screen overflow-hidden">
      <HomeSidebarV1 />
      <div className="flex-1 overflow-auto min-h-screen bg-white py-2 ml-56">
        <div className="mx-auto px-2">
          <div className="bg-white h-full overflow-y-auto">
            <div className="p-6">
              <div className="flex items-center justify-between mb-6">
                <div className="flex items-center text-md text-gray-500">
                  <span className="hover:text-gray-700 cursor-pointer" onClick={() => navigate('/compare')}>Comparison AI</span>
                  <ChevronRight className="h-4 w-4 mx-2" />
                  <span className="font-medium text-gray-900">Details</span>
                </div>
              </div>

              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center space-x-2">
                  <div className="relative">
                    <Input
                      type="text"
                      placeholder="Search"
                      className="pl-8 pr-4 py-1 w-48 bg-white border-gray-300 text-gray-900 focus:ring-0 h-9"
                      disabled
                    />
                    <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                  </div>
                </div>
                <Button
                  variant="outline"
                  size="sm"
                  className="text-gray-700 hover:text-gray-900 border border-gray-300 hover:bg-white"
                  disabled
                >
                  <ExternalLink className="h-4 w-4 mr-2" />
                  Export
                </Button>
              </div>

              <div className="mb-6">
                <h3 className="text-lg font-semibold mb-4">Uploaded document</h3>
                <div className="grid grid-cols-1 gap-4">
                  <div className="flex items-center p-4 rounded-lg border border-gray-200 bg-white">
                    <div className="w-8 h-8 rounded-full bg-gray-100 animate-pulse flex items-center justify-center mr-3">
                      <FileText className="w-4 h-4 text-gray-400" />
                    </div>
                    <div className="h-4 bg-gray-200 rounded animate-pulse w-48" />
                  </div>
                </div>
              </div>

              <h3 className="text-lg font-semibold mb-4">Summary</h3>
              <div className="mb-6 border border-gray-200 rounded-lg">
                <div className="p-4 relative">
                  <div className="max-h-20 overflow-hidden">
                    {[...Array(3)].map((_, index) => (
                      <div
                        key={index}
                        className="h-4 bg-gray-200 rounded animate-pulse mb-2"
                        style={{ width: `${Math.random() * 30 + 70}%` }}
                      />
                    ))}
                  </div>
                  <div className="absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-t from-white to-transparent" />
                  <div className="absolute bottom-0 left-0 right-0 flex justify-center">
                    <div className="w-24 h-8 bg-gray-200 rounded-full transform translate-y-1/2 animate-pulse" />
                  </div>
                </div>
              </div>

              <h2 className="text-lg font-semibold mb-4">Table</h2>

              <Table className="border-collapse border border-gray-200">
                <TableHeader>
                  <TableRow>
                    <TableHead className="border-y border-gray-200">Schema Fields</TableHead>
                    <TableHead className="border border-gray-200"></TableHead>
                    <TableHead className="border border-gray-200"></TableHead>
                    <TableHead className="w-[100px] border-y border-r border-gray-200">Status</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {[...Array(5)].map((_, index) => (
                    <TableRow key={index}>
                      <TableCell className="border-y border-gray-200">
                        <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                      </TableCell>
                      <TableCell className="border border-gray-200">
                        <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                      </TableCell>
                      <TableCell className="border-y border-gray-200">
                        <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                      </TableCell>
                      <TableCell className="border-y border-gray-200">
                        <div className="h-4 w-16 bg-gray-200 rounded animate-pulse"></div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function CompareDetailError({ error }: { error: string }) {
  return (
    <div className="flex">
      <HomeSidebarV1 />
      <div className="flex-1 min-h-screen bg-white py-2 ml-56">
        <div className="mx-auto px-2">
          <div className="bg-white h-full overflow-y-auto">
            <div className="p-6">
              <h1 className="text-xl font-semibold mb-6">Error</h1>
              <p className="text-red-500 mb-4">{error}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
