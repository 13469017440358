import React, { useRef, useEffect } from 'react';
import { X } from 'lucide-react';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { ResizablePanelGroup, ResizablePanel, ResizableHandle } from "./ui/resizable";

import '@react-pdf-viewer/core/lib/styles/index.css';

interface DocumentPreviewDialogProps {
  isOpen: boolean;
  onClose: () => void;
  previewUrl: string;
  fileType: 'pdf' | 'image' | 'excel';
}

const DocumentPreviewDialog: React.FC<DocumentPreviewDialogProps> = ({
  isOpen,
  onClose,
  previewUrl,
  fileType,
}) => {
  const dialogRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  const getPreviewContent = () => {
    switch (fileType) {
      case 'pdf':
        return (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <Viewer
              fileUrl={previewUrl}
              defaultScale={SpecialZoomLevel.PageFit}
            />
          </Worker>
        );
      case 'image':
        return (
          <img
            src={previewUrl}
            alt="Document Preview"
            className="max-w-full max-h-full object-contain"
          />
        );
      case 'excel': {
        const encodedUrl = encodeURIComponent(previewUrl);
        const viewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodedUrl}`;
        return (
          <iframe
            src={viewerUrl}
            className="w-full h-full border-0"
            title="Excel Preview"
          />
        );
      }
      default:
        return <div>Unsupported file type</div>;
    }
  };

  return (
    <div className="fixed inset-0 z-50 overflow-hidden bg-black bg-opacity-30">
      <ResizablePanelGroup direction="horizontal">
        <ResizablePanel className="bg-transparent" collapsible={false} minSize={25}>
        </ResizablePanel>
        <ResizableHandle withHandle />
        <ResizablePanel className="bg-white shadow-xl" defaultSize={40.5} minSize={40.5}>
          <div
            ref={dialogRef}
            className="relative h-full w-full"
          >
            <button
              onClick={onClose}
              className="absolute top-4 right-4 z-10 text-gray-500 hover:text-gray-700 focus:outline-none transition-colors"
              aria-label="Close preview"
            >
              <X className="h-6 w-6" />
            </button>
            <div className="w-full h-full relative overflow-auto p-4">
              {getPreviewContent()}
            </div>
          </div>
        </ResizablePanel>
      </ResizablePanelGroup>
    </div>
  );
};

export default DocumentPreviewDialog;
